/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Button from '@mui/joy/Button';
// import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from '../components/TwoSidedLayout';

export default function Hero() {
  return (
    <TwoSidedLayout>
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Babystep Technologies
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Crafting Software with Heart and Expertise 
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
       Babystep Technologies is committed to solving real-world problems with innovative software solutions. We offer expert software consulting for small businesses and develop open source software with comprehensive hosting and support. Our motivated experts bring a personal touch to every project, ensuring impactful and reliable results. We care deeply about our work and the clients we serve, delivering solutions that truly make a difference. 
      </Typography>
      <Button size="lg" endDecorator={<ArrowForward fontSize="large" />}>
        Contact Us
      </Button>
    </TwoSidedLayout>
  );
}
